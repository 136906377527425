import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import config from '../../../../data/SiteConfig'
import { ButtonLink } from '../../../components/Button'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../../layout'))

const buttonDescWrapperStyle = { margin: '0 20px 20px 20px' }
const posterContainerStyle = { marginBottom: '20px' }

const seoDescription =
  'Play free Tambola / Housie / Indian Bingo online Play free Tambola ads | Ad free Tambola, Enjoy with family, friends.'

const Tamobola = ({ location }) => (
  <Layout
    title={`Tambola / Housie Game without Ads – ${config.siteTitle}`}
    seoDescription={seoDescription}
    seoImage={config.tambolaCover}
    href={location.href}
  >
    <div className="container text-center">
      <h1>Play Free Paperless Tambola without Ads</h1>
      <div className="tambola-entrance flex-center">
        <div>
          <ButtonLink title="Become A Host" className="tambola-entrance-button" href="tambola/host">
            Become A Host
          </ButtonLink>
          <p style={buttonDescWrapperStyle} className="cta-effect black">
            A Host can set the rules and call the Tambola Numbers.
          </p>
        </div>
        <div>
          <ButtonLink
            title="Become A Player"
            className="tambola-entrance-button"
            href="tambola/generate-ticket"
          >
            Become A Player
          </ButtonLink>
          <p style={buttonDescWrapperStyle} className="cta-effect black">
            A Player can Generate Ticket, and Play.
          </p>
        </div>
      </div>
      <div>
        <ButtonLink title="Tambola Rules" className="tambola-entrance-button" href="tambola/rules">
          Tambola Rules
        </ButtonLink>
        <p style={buttonDescWrapperStyle} className="cta-effect black">
          Game Rules, In case you wanna check
        </p>
      </div>
      <div style={posterContainerStyle}>
        <StaticImage
          src="../../../../static/images/etc/tambola-cover.webp"
          alt="Tambola cover"
          title="Tambola cover"
          className="w-100"
          placeholder="blurred"
        />
      </div>
    </div>
  </Layout>
)

export default Tamobola
